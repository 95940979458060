:root {
    --control-background-color: #f4f9f0;
    --control-border-color: #88c656;
    --border-color-gray: #d5d5d5;
}

html {
    height: 100%;
}

body {
    font-family: sans-serif, 'Poppins';
    font-size: 1.2rem !important;
    line-height: 1.42857143;
    color: #333;
    height: 100%;
    overflow: hidden;
}

#app {
    /*overflow-wrap: initial;*/
    height: 100%;
}

.invalid-container {
    background-color: #F0F0F0;
    height: 100%;
}

.invalid-header {
    background-color: #0973ba;
    height: 125px;
    padding: 6px;
}

.error-page {
    height: 100%;
}

.error-page-container {
    border-radius: 6px;
    height: 100%;
    background-color: #fff;
    z-index: 2;
    cursor: pointer;
    padding-top: 50%;
}

.error-page-container .message {
    font-size: 3em;
    text-align: center;
}

#awesome-pdf-viewer>.main {
    height: calc(90vh - 140px) !important;
}

#awesome-pdf-viewer>.main>.right-panel {
    position: relative;
}

.signing-page-main-container,
.pdf-preview-container,
#awesome-pdf-viewer {
    height: 100%;
}

#awesome-pdf-viewer>.main,
#awesome-pdf-viewer>.main>.side-panel {
    height: calc(100% - 45px) !important;
}

.view-layout>.main>.side-panel {
    z-index: 9998 !important;
}

.read-mode-toolbar {
    width: 355px !important;
}

.steps-wrapper {
    height: calc(100% - 60px);
}

.steps-body.without-footer {
    height: calc(100% - 55px) !important;
}

.header-container {
    background-color: #0973ba;
    /*background-color: var(--headerBgColor);*/
    height: 60px;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
}

.header-nav {
    margin: auto;
    width: 100%;
    text-align: center;
    color: white;
    display: flex;
    flex-wrap: wrap;
}

.header-nav>.title {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
}

.header-nav>.company-logo {
    max-height: 50px;
    margin: auto;
}

.page-container {
    height: calc(100vh - 135px);
    padding: 20px;
}

.page-parts-container {
    border: 2px solid #909090;
    border-radius: 5px;
    margin: auto;
    text-align: center;
    border-width: 3px;
    font-size: 15px;
}

.steps-body {
    border: none !important;
}

@media (max-width: 481px) {
    .shepherd-element-left[data-popper-placement="right"] {
        margin-left: 12px !important;
        margin-right: 0px !important;
    }
    .shepherd-element-right[data-popper-placement="left"] {
        margin-right: 12px !important;
        margin-left: 0px !important;
    }
    .shepherd-element-left[data-popper-placement="right"] button.shepherd-button:after {
        left: -83% !important;
        border: 10px solid transparent !important;
        border-right: 12px solid #FF9800 !important;
    }
    .shepherd-element-right[data-popper-placement="left"] button.shepherd-button:before {
        right: -83% !important;
        border: 10px solid transparent !important;
        border-left: 12px solid #FF9800 !important;
    }
    .shepherd-element[data-popper-placement="left"],
    .shepherd-element[data-popper-placement="right"] {
        margin-right: 12px !important;
    }
}

@media (min-width:482px) and (max-width: 960px) {
    .shepherd-element-left[data-popper-placement="right"] {
        margin-left: 12px !important;
        margin-right: 0px !important;
    }
    .shepherd-element-right[data-popper-placement="left"] {
        margin-right: 12px !important;
        margin-left: 0px !important;
    }
    .shepherd-element-left[data-popper-placement="right"] button.shepherd-button:after {
        left: -29px !important;
        border: 15px solid transparent !important;
        border-right: 15px solid #FF9800 !important;
    }
    .shepherd-element-right[data-popper-placement="left"] button.shepherd-button:before {
        right: -29px !important;
        border: 15px solid transparent !important;
        border-left: 15px solid #FF9800 !important;
    }
    .shepherd-element[data-popper-placement="left"],
    .shepherd-element[data-popper-placement="right"] {
        margin-right: 12px !important;
    }
}

@media (min-width:961px) {
    .shepherd-element-left[data-popper-placement="right"] {
        margin-left: 18px !important;
        margin-right: 0px !important;
    }
    .shepherd-element-right[data-popper-placement="left"] {
        margin-right: 18px !important;
        margin-left: 0px !important;
    }
    .shepherd-element-left[data-popper-placement="right"] button.shepherd-button:after {
        left: -39px;
        border: 20px solid transparent !important;
        border-right: 20px solid #FF9800 !important;
    }
    .shepherd-element-right[data-popper-placement="left"] button.shepherd-button:before {
        right: -39px;
        border: 20px solid transparent !important;
        border-left: 20px solid #FF9800 !important;
    }
}

.shepherd-element[data-popper-placement='left'] button.shepherd-button:after,
.shepherd-element[data-popper-placement='right'] button.shepherd-button:before {
    pointer-events: none;
}

.shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered) {
    visibility: hidden;
}

.step-layout {
    height: 100%;
}

.awesome-pdf-viewer-radio-button:checked+.awesome-pdf-viewer-radio-button-label:before {
    top: -2px !important;
    font-weight: 900 !important;
}

.canvasWrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.step-layout-left-panel-header,
.step-layout-body-header {
    font-size: 20px;
    padding: 0px 0px 5px 0px;
    font-weight: bolder;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.step-layout-body-header>.sub-title {
    font-weight: bold;
    font-size: 14px;
    height: 100px;
    color: #747474;
}

.step-layout-left-panel-body {
    padding: 0px 15px 0px 0px;
}

.step-layout-body {
    padding: 8px 8px 8px 8px !important;
}

.step-layout-left-panel-body,
.step-layout-body {
    height: calc(100%);
    overflow-y: auto;
    overflow-x: hidden;
}

.step-layout-body.without-header {
    height: 100%;
}

.step-layout-body {
    width: 100%;
    border: 2px solid #909090;
    border-radius: 5px;
    margin: auto;
}

.step-layout-content {
    min-height: 400px;
    height: 100%;
    border: none !important;
}

.step-layout-header+.step-layout-content {
    height: calc(100% - 50px);
}

.step-layout-body-container {
    margin: 0px auto 0px auto;
    padding: 8px !important;
    border: none !important;
}

.step-layout-left-panel {
    background-color: white;
}

.step-layout-left-panel,
.step-layout-body-container {
    border: 1px solid #eeeeee;
    border-radius: 2px;
    height: 100%;
}

.step-layout-body.white {
    background-color: white;
}

.step-layout-body.transparent {
    background-color: transparent;
}

#awesome-multi-steps-btn-previous {
    background-color: white !important;
    border-color: #0973ba !important;
    color: #0973ba !important;
    border-width: medium;
    font-weight: 600;
}

#awesome-multi-steps-btn-next {
    border-width: medium;
    font-weight: 600;
    border-color: #89c05a !important;
}

.dropdown-toggle.btn.btn-success {
    color: black;
    background-color: var(--control-background-color);
    border-color: #7ad25b;
    border-width: medium;
    border-radius: 4px !important;
    width: 100px;
    text-align: left;
}

.dropdown-toggle.btn.btn-success::after {
    position: absolute;
    right: 15px;
    top: 18px;
}

.YearMonthDay.col {
    display: inline-flex;
}

.select-control {
    margin: auto;
    margin-left: 0px;
}

.select-control>.dropdown {
    width: max-content;
}

.scrollable-menu {
    height: auto;
    max-height: 400px;
    overflow-x: hidden;
    display: inline !important;
    font-size: 25px !important;
}


/*Custom Checkbox START*/


/* Customize the label (the container) */

.consent-checkbox {
    display: block;
    position: relative;
    padding: 9px 0px 0px 35px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


/* Hide the browser's default checkbox */

.consent-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 16px;
    width: 16px;
    top: 20px;
    left: 0px;
    z-index: 99;
}


/* Create a custom checkbox */

.checkmark {
    position: absolute;
    top: 15px;
    left: 0px;
    height: 23px;
    width: 23px;
    background-color: #eee;
    border: 3px solid var(--control-border-color);
    border-radius: 4px !important;
}

#chkConsent.invalid+span {
    border: 3px solid red;
    border-radius: 4px !important;
}


/* On mouse-over, add a grey background color */

.consent-checkbox input:hover~.checkmark {
    background-color: #ccc;
}


/* When the checkbox is checked, add a blue background */

.consent-checkbox input:checked~.checkmark {
    background-color: var(--control-background-color);
}


/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the checkmark when checked */

.consent-checkbox input:checked~.checkmark:after {
    display: block;
}


/* Style the checkmark/indicator */

.consent-checkbox .checkmark:after {
    left: 5px;
    top: 2px;
    width: 7px;
    height: 11px;
    border: solid #9fe350;
    border: solid var(--control-border-color);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}


/*Custom Checkbox END*/

.custom-input {
    color: var(--control-border-color);
    background-color: var(--control-background-color);
    border-color: var(--control-border-color);
    border-radius: 5px !important;
    width: 100%;
    width: 100%;
    border-width: 2px;
    height: 40px;
    font-size: 20px;
}

.custom-textarea {
    color: var(--control-border-color);
    background-color: var(--control-background-color);
    border-color: var(--control-border-color);
    border-radius: 5px !important;
    width: 100%;
    width: 100%;
    border-width: 2px;
    height: 100px;
    font-size: 20px;
}

.textarea-size {
    resize: none !important;
    height: 100px !important;
}

#controlled-tab-example-tabpane-home {
    height: 100%;
    background-color: transparent !important;
}

#controlled-tab-example-tabpane-profile {
    height: 100%;
    background-color: white !important;
}

.payment-refund-container {
    height: 100%;
    margin-right: 0px !important;
    padding-right: 0px !important;
    background-color: white;
}

.payment-refund-container .title,
.estimated-voucher-container .title,
.tax-payment-container .title,
.payment-instruction-container .title {
    margin-bottom: 5px;
    margin-left: 0px;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.89;
    letter-spacing: normal;
    color: #000000;
}

.payment-refund-container .body,
.estimated-voucher-container .body {
    padding: 0px 10px;
    overflow: auto;
    height: 100%;
}

.section-title {
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: #0973ba;
    clear: both;
}

.preSelect {
    clear: both;
    margin: 20px 1px;
}

.sub-section {
    clear: both;
}

.sub-section div {
    width: 50%;
    position: relative;
}

.sub-section .authority {
    font-size: 50px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #000000;
}

.sub-section .amount {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
}

.sub-section .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    padding: inherit;
    font-size: 15px;
}

.total-amount-green {
    border-top: 1px solid #000;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    width: 40% !important;
}

.currencytext {
    text-align: right;
    padding-right: 0px;
    /*font-family: monospace;*/
    font-family: sans-serif, 'Roboto', 'Poppins';
}

.flrt {
    float: right;
}

.orange {
    color: darkorange;
}

.green {
    color: #86bf51;
}

.red {
    color: #e00d0d;
}

.bold {
    color: #000;
}

.fllft {
    float: left;
}

.file-node-selected {
    outline: 0;
    background: rgba(51, 51, 204, 0.2);
}

li span.rct-text span.rct-node-clickable {
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    width: 160px !important;
}

.rct-checkbox {
    display: none;
}

.rct-node-icon {
    color: #008af8 !important;
}

.rct-title {
    overflow: hidden;
    width: 150px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline;
}

.total-amount-red {
    border-top: 1px solid #000;
    font-size: 20px;
    font-weight: 700;
    width: 40% !important;
}

.nav-tabs {
    border-radius: 5px !important;
}

.nav-tabs .nav-link {
    border: 2px solid #8bc656 !IMPORTANT;
    font-size: 18px !important;
    flex: 1 1 auto;
    text-align: center;
    padding: 10px 5px 10px 5px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #0973ba !important;
    background-color: #86bf516e !important;
    font-size: 18px;
    border: 2px solid #86bf51 !IMPORTANT;
}

.form-check-input {
    width: 30px;
    height: 30px;
    margin-left: -17px !important;
}

.form-check-label {
    margin-bottom: 15px !important;
    margin-left: 35px !important;
    padding-top: 6px !important;
}

.sign-completed-body {
    text-align: center;
}

.sign-completed-icon {
    height: 85px;
}

.link-email {
    color: #007bff !important
}

.link-email:hover {
    cursor: pointer !important;
    text-decoration: underline !important
}

.custom-button {
    background-color: #5ffc5f1c;
    border: 2px solid #83c786;
    height: 50px;
    border-radius: 5px;
    display: flex;
    padding: 8px;
    cursor: pointer;
}

.custom-button.left {
    float: left;
}

.custom-button.center {
    margin: auto;
}

.custom-button.xl {
    width: 285px;
}

.custom-button.lg {
    width: 225px;
}

.custom-button.md {
    width: 175px;
}

.custom-button.sm {
    width: 125px;
}

.custom-button.right {
    float: right;
}

.custom-button.right {
    float: right;
}

.custom-button:hover {
    background-color: #9fff9f6b;
}

.custom-button-icon-container {
    width: 25px;
}

.custom-button-text-container {
    overflow: hidden;
    user-select: none;
    color: #0274bb;
    font-weight: bold;
    font-size: 14px !important;
    margin: auto auto auto 25px;
    font-family: sans-serif, 'Roboto', 'Poppins';
}

.text-align-center {
    text-align: center !important;
}

.form-control-green,
.form-control-green:focus {
    border-color: #7ad25b;
    outline: 0 none;
    border-width: medium;
    /*color: var(--control-border-color);*/
    background-color: var(--control-background-color);
    border-radius: 4px !important;
    text-align: left;
    font-weight: 600;
}

.form-control-red,
.form-control-red:focus {
    border-color: red;
    outline: 0 none;
    border-width: medium;
    color: red;
    background-color: var(--control-background-color);
    border-radius: 4px !important;
    text-align: left;
}

.form-control-red::-webkit-input-placeholder {
    color: red !important;
}

.form-control-red:-moz-placeholder {
    color: red !important;
}

.form-control-red::-moz-placeholder {
    color: red !important;
}

.form-control-red:-ms-input-placeholder {
    color: red !important;
}

.form-rounded {
    border-radius: 0.3rem !important;
}

.form-control::-webkit-input-placeholder {
    color: #7ad25b;
}

.form-control:-moz-placeholder {
    color: #7ad25b;
}

.form-control::-moz-placeholder {
    color: #7ad25b;
}

.form-control:-ms-input-placeholder {
    color: #7ad25b;
}

#overlay-loader[data-show="true"] {
    display: block !important;
}

.form-label-bold {
    font-size: medium;
    font-weight: 600;
}

.header-bold {
    font-size: small;
    font-weight: 600;
}

.ssnAuthDiv {
    height: 75vh;
    overflow: scroll;
    border: 1px solid gray;
    margin: 15px 0px;
    padding: 10px;
}

.ssnContinueBtn {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.qnaAuthDiv {
    height: 75vh;
    overflow: scroll;
    border: 1px solid gray;
    margin: 15px 0px;
    padding: 10px;
}

.qnaContinueBtn {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.mobileAuthDiv {
    height: 75vh;
    overflow: scroll;
    border: 1px solid gray;
    margin: 15px 0px;
    padding: 10px;
}

.mobileContinueBtn {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.emailAuthDiv {
    height: 75vh;
    overflow: scroll;
    border: 1px solid gray;
    margin: 15px 0px;
    padding: 10px;
}

.emailContinueBtn {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.wid140 {
    width: 140px
}

.wid170 {
    width: 170px
}

.fontMed {
    font-size: medium;
}

.fontItalics {
    font-style: italic
}

.modal-footer {
    padding: 0.50rem !important;
}

#overlay-loader {
    background: #ffffff;
    color: #666666;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 10000;
    top: 0;
    left: 0;
    float: left;
    text-align: center;
    padding-top: 25%;
    opacity: .80;
}

.font15 {
    font-size: 15px;
}

#overlay-loader>.spinner {
    margin: 0 auto;
    height: 40px;
    width: 40px;
    animation: rotate 0.8s infinite linear;
    border: 3px solid #0973ba;
    /*border: 3px solid var(--headerBgColor);*/
    border-right-color: transparent;
    border-radius: 50%;
    top: 50%;
    left: 43%;
    position: fixed;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.marB0 {
    margin-bottom: 0px !important;
}

.marRPer10 {
    margin-right: 10%;
}

.marT5 {
    margin-top: 5px;
}

.marT10 {
    margin-top: 10px;
}

.marLeft30 {
    margin-left: 33px;
}


/* Bootstrap Modal Customization */

.modal-dialog {
    border-radius: 5px !important;
}

.modal-content {
    border: 3px solid #89c05a !important;
    border-radius: 5px !important;
}

.bootbox-accept {
    border-radius: 25px !important;
    color: white !important;
    /*border-color: #9fe350 !important;*/
    background-color: #89c05a !important;
    width: 115px !important;
    border-width: medium !important;
    font-weight: 600 !important;
    /*font-size: 1rem !important;*/
}

.bootbox-cancel {
    background-color: white !important;
    border-color: #0973ba !important;
    color: #0973ba !important;
    border-radius: 25px !important;
    width: 115px !important;
    font-weight: 600 !important;
    border-width: medium;
}

.bootbox-delegate {
    background-color: white !important;
    border-color: #0973ba !important;
    color: #0973ba !important;
    border-radius: 25px !important;
    width: 115px !important;
}

.bootbox-cancel-signing {
    background-color: white !important;
    border-color: red !important;
    color: red !important;
    border-radius: 25px !important;
    font-weight: 600 !important;
    border-width: medium;
}

.bootbox-title-icon {
    margin: 0px 10px 0px 0px;
}

.modal-footer {
    display: block !important;
    text-align: right !important;
}

.modal-footer>.btn-secondary {
    color: #0973ba !important;
    border-color: #0973ba !important;
    background-color: white !important;
    border-width: medium !important;
    font-weight: 600 !important;
}

.modal-footer>.btn-primary {
    color: white !important;
    /*border-color: #9fe350 !important;*/
    background-color: #89c05a !important;
    border-width: medium !important;
    font-weight: 600 !important;
}


/* End Of Bootstrap Modal Customization */

#linkResendOTP,
#linkResendMobileOtp {
    color: #0973ba !important;
    border-color: #0973ba !important;
    background-color: white !important;
    border-width: medium !important;
    font-weight: 600 !important;
    float: none !important;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-style: italic;
    font-weight: 400 !important;
}

::-moz-placeholder {
    /* Firefox 19+ */
    font-style: italic;
    font-weight: 400 !important;
}

:-ms-input-placeholder {
    /* IE 10+ */
    font-style: italic;
    font-weight: 400 !important;
}

:-moz-placeholder {
    /* Firefox 18- */
    font-style: italic;
    font-weight: 400 !important;
}

[id^="signature-pad-name-type"] {
    border-color: #7ad25b !important;
    outline: 0 none;
    border-width: medium !important;
    /*color: var(--control-border-color);*/
    background-color: var(--control-background-color);
    border-radius: 4px !important;
    text-align: left;
    font-weight: 600;
}

.signature-pad-wrapper {
    border-color: #7ad25b !important;
    outline: 0 none;
    border-width: medium !important;
    color: var(--control-border-color);
    background-color: var(--control-background-color);
    border-radius: 4px !important;
    text-align: left;
}

.signature-pad {
    /* width: 100%;
    padding-top: 20px;*/
}

.sigCanvas {
    width: auto;
}


/*[id^="signature-pad-document-control"] {
    border-color: #7ad25b;
    outline: 0 none;
    border-width: medium;
    color: var(--control-border-color);
    background-color: var(--control-background-color);
    border-radius: 4px !important;
    text-align: left;
}*/

.btn-clear-signature,
.btn-clear-signature:hover {
    background-color: transparent;
    border-color: transparent;
    color: #0973ba;
    font-weight: 600 !important;
    border: none;
    box-shadow: none !important;
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show>.btn-warning.dropdown-toggle {
    background-color: transparent;
    border-color: transparent;
    color: #0973ba;
    font-weight: 600 !important;
    border: none;
}

.button-bg {
    padding: 0px 20px 0px 20px;
    font-size: 20px !important;
    height: 50px;
    border-radius: 5rem !important;
    color: white !important;
    /*border-color: #9fe350 !important;*/
    background-color: #89c05a !important;
    font-weight: 600;
}

.button-bg[disabled] {
    opacity: .5;
}

.summary-begin-button {
    margin-top: 15px;
}

.summary-begin-button-container {
    text-align: center;
}

.step-layout-body>.tab-content {
    height: 78%;
}

.document-control {
    z-index: 2;
}

.awesome-pdf-viewer-tooltip {
    z-index: 5 !important;
}

.awesome-pdf-viewer-radio-button+.awesome-pdf-viewer-radio-button-label {
    border: 1px solid #8bc34a !important;
}

.document-control span {
    margin: 5%;
}

.btn-primary {
    background-color: #88c656 !important;
    border: 1px solid #88c656;
    border-color: #88c656 !important;
}

.btn-primary,
.btn-light,
.btn-info {
    border-radius: 50px !important;
    min-width: 120px;
    font-weight: 500;
    /*font-size: 14px;*/
}

.landingPageFooter>.btn-primary,
.btn-light,
.btn-info {
    border-radius: 50px !important;
    /*font-size: 14px;*/
}

.landingPageFooterBtnWidth {
    min-width: 80vw;
    min-height: 6vh;
    border-width: medium;
    font-weight: 600;
}

.btn-primary:hover {
    color: #fff;
    background-color: #88c656 !important;
    border-color: #88c656 !important;
}

.btn-secondary {
    min-width: 0 !important;
    border: 2px solid #8bc656 !important;
    background-color: #f3f9ee !important;
    color: black !important;
    min-width: 100px !important;
    border-radius: 5rem !important;
    float: left;
}

.btn-secondary:hover {
    background-color: #e8f4dd;
    /*border-color: #9fe350;*/
}

.btn-secondary:active {
    background-color: #e8f4dd !important;
    /*border-color: #9fe350 !important;*/
}

div#ddlCountryCode {
    width: 150px;
}

.phoneNumeberTextBox {
    height: 41px !important;
    border-radius: 5px !important;
}

.spouse-details-phone-number-container {
    display: inline-flex;
}

.bootbox>.modal-dialog {
    margin: .5rem !important;
}


/*.modal-dialog {
    margin: -50px auto 0px auto
}*/

.modal-title {
    width: 100%;
    float: right;
    margin-right: 10px;
}

.popover {
    border: 1px solid #0973ba;
    z-index: 9999;
    max-width: 225px;
    /*border: 1px solid var(--headerBgColor);*/
}

.popover-header {
    background-color: #0973ba;
    /*background-color: var(--headerBgColor) !important;*/
    color: white;
    /*color: var(--headerForeColor);*/
    font-size: 12px;
    font-weight: bold;
    margin: 1px;
}

.rct-node-icon {
    color: #717171;
}

li span.rct-text span.rct-node-clickable {
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    width: 200px !important;
}

.rct-node-clickable:focus {
    outline: 0;
    background: transparent;
}

.react-checkbox-tree:not(.rct-native-display) .rct-checkbox {
    display: none;
}

.react-checkbox-tree {
    display: block;
}

.header-left-container {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: 40px;
    position: relative;
}

.header-right-container {
    background-color: #0973ba;
    height: 60px;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    width: calc(100% - 20%);
}

.header-account {
    margin-right: 15px;
}

.header-mydownload,
.header-contact-info,
.header-logout,
.header-account,
.header-taxYear,
.header-taxReturn {
    float: right;
    color: white;
    color: var(--headerForeColor);
    cursor: pointer;
    margin-left: 15px;
    padding: 12px 0px 0px 5px;
}

.header-contact-info a {
    color: white;
    color: var(--headerForeColor);
}

.header-contact-info a svg {
    max-width: 16px;
    max-height: 16px;
    margin-right: 5px;
}

.header-contact-info:hover,
.header-logout:hover {
    text-decoration: underline;
}

.header-taxYear a svg {
    max-width: 16px;
    max-height: 16px;
    margin-right: 5px;
}

.header-account a svg {
    max-width: 16px;
    max-height: 16px;
    margin-right: 5px;
}

.header-taxYear:hover {
    cursor: text;
}

.mail {
    font-size: 14px;
    color: #88c656;
    font-weight: bold;
}

.mail a:hover {
    cursor: pointer;
}

.account-menu,
.taxYear-menu,
.taxReturn-menu {
    list-style: none;
    padding: 0 !important;
    margin: 0 !important;
}

.header-account a::after
/*, .header-taxYear a::after, .header-taxReturn a::after*/

{
    content: "";
    width: 0px;
    height: 1px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 7px solid #ffffff;
    position: absolute;
    margin-left: 5px;
    margin-top: 8px;
    z-index: 100;
}

#sidebarMenu {
    height: calc(100% - 57px);
    position: fixed;
    left: 0;
    width: 225px;
    margin-top: 57px;
    transform: translateX(-250px);
    transition: transform 250ms ease-in-out;
    /*background: linear-gradient(180deg, #FC466B 0%, #3F5EFB 100%);*/
    background-color: #0973ba;
    /*background-color: var(--headerBgColor);*/
    /*overflow: scroll;*/
    z-index: 9999 !important;
}

.sidebarMenuInner {
    margin: 0;
    padding: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.10);
}

.sidebarMenuInner li {
    list-style: none;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    padding: 20px;
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.10);
}

.sidebarMenuInner li span {
    display: block;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.50);
}

.sidebarMenuInner li a {
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
}

input[type="checkbox"]:checked~#sidebarMenu {
    transform: translateX(0);
}

#openSidebarMenu {
    transition: all 0.3s;
    box-sizing: border-box;
    display: none;
}

.sidebarIconToggle {
    transition: all 0.3s;
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    z-index: 99;
    margin: 22px 0px 0px 0px;
    height: 22px;
    width: 22px;
}

div#ddlStates>div {
    border-color: #7ad25b;
    border-width: medium;
    color: var(--control-border-color);
    background-color: var(--control-background-color);
    border-radius: 4px !important;
    text-align: left;
}

div#ddlStates.invalid>div {
    border-color: red;
    border-width: medium;
    color: var(--control-border-color);
    background-color: var(--control-background-color);
    border-radius: 4px !important;
    text-align: left;
}

.spinner {
    transition: all 0.3s;
    box-sizing: border-box;
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: #fff;
}

.horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
}

.diagonal.part-1 {
    position: relative;
    transition: all 0.3s;
    box-sizing: border-box;
    float: left;
}

.diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
}

input[type=checkbox]:checked~.sidebarIconToggle>.horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    opacity: 0;
}

input[type=checkbox]:checked~.sidebarIconToggle>.diagonal.part-1 {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(135deg);
    margin-top: 8px;
}

input[type=checkbox]:checked~.sidebarIconToggle>.diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(-135deg);
    margin-top: -9px;
}

.show {
    display: block !important;
}

.sidebar {
    width: 225px !important;
    min-height: calc(100vh - 42px);
    user-select: none;
}

.navbar-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    font-size: 1.5rem !important;
}

.navbar-nav {
    float: left;
    margin: 0;
}

.navbar-nav>li {
    text-align: left;
    border-top: 1px solid #8e8e8e99;
    font-size: 15px;
    padding: 10px;
}

.navbar-nav>li:first-child {
    border-top: none;
    white-space: pre-line;
}

.navbar-nav>li:first-child>h4 {
    border-top: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.navbar-nav>li:last-child {
    border-bottom: 1px solid #8e8e8e99;
}

.dropup,
.dropright,
.layout-dropdown,
.dropleft {
    position: relative;
}

.sidebar .nav-item .nav-link {
    display: block;
    text-align: left;
    padding: 1rem;
    width: 225px;
}

.sidebar .nav-item .nav-link {
    color: #fff;
}

.sidebar .nav-item.layout-dropdown .layout-dropdown-toggle::after {
    display: block;
}

navbar-nav .nav-item.layout-dropdown .layout-dropdown-toggle span {
    margin: 10px;
}

.sidebar .nav-item .nav-link span {
    font-size: 1.5rem;
    display: inline;
}

.nav-link span {
    color: #fff;
    text-shadow: none;
}

.app-header {
    height: 45px;
    background-color: #0a72bb;
    padding: 5px;
    z-index: 2;
    position: relative;
}

.layout-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
}

.layout-dropdown-menu {
    box-shadow: none;
    position: static !important;
    margin-left: 30px;
    top: 0;
    background-color: transparent;
    border: none;
}

.navbar-nav .layout-dropdown-menu {
    position: static;
    float: none;
}

.sidebar .nav-item .layout-dropdown-menu {
    -webkit-transform: none !important;
    transform: none !important;
    left: calc(50px + 0.5rem) !important;
    margin: 0;
}

.layout-dropdown-menu .layout-dropdown-item {
    display: block;
    width: 100%;
    padding: 0.50rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #fff;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-size: 14px;
}

.layout-dropdown-menu.show {
    padding-top: 0px;
}

a.active.layout-dropdown-item {
    text-decoration: underline;
}

.myaccount-text.phonenumber {
    display: inline-flex;
}

.myaccount .modal-title {
    color: #0973ba;
    font-size: 14px;
    font-weight: bold;
}

.myaccount-text {
    padding: 10px;
    font-size: 12px;
}

.my-account-phonenumber {
    font-size: 15px;
    height: 38px !important;
}

.myaccount-text {
    padding: 10px;
    font-size: 12px;
}

.myaccount-changenumber {
    padding-right: 20px;
    padding-left: 4px;
}

.myaccount-countrycode {
    padding-right: 0px;
    padding-left: 20px;
}

.assignToDelegate .modal-header {
    background-color: #0973ba;
    color: white;
}

.compulsory-star1 {
    color: #c50505;
    font-size: 9px;
    padding-left: 2px;
    position: inherit;
    top: -2px;
}

.steps-footer {
    z-index: 11;
}

@supports not (-webkit-touch-callout: none) {
    /* CSS for other than iOS devices */
    @media screen and (max-device-width: 960px) and (orientation: landscape) {
        .steps-footer {
            position: fixed;
            bottom: 0%;
            background-color: white;
            padding: 7px !important;
        }
        .steps-wrapper {
            height: 100vh !important;
        }
    }
    #app {
        overflow: auto;
    }
}

@media screen and (min-device-width: 1000px) and (orientation:landscape) {
    .middle-section .icon-bar a {
        width: 40px !important;
    }
}

@media screen and (min-aspect-ratio: 13/9) {
    .d-md-block {
        display: block !important;
    }
    .middle-section .icon-bar a {
        width: 24px;
    }
}

.modal {
    z-index: 9999;
}

.text-align-center {
    text-align: center;
}

.marB10 {
    margin-bottom: 10px !important;
}

.fa-info-circle:before {
    padding-right: 5px;
}

.steps-wrapper>.steps-body>.step {
    animation-fill-mode: none !important
}

.container-height {
    height: calc(100% - 90px) !important;
}

.form-horizontal-height {
    height: calc(100% - 55px) !important;
}

.bold-text {
    font-weight: 600;
}

.span-nav {
    font-size: 17px;
}

.authentication-form.is-invalid,
.was-validated .form-control:invalid {
    border-color: red;
    background-image: none;
}

.form-control.is-valid,
.was-validated .form-control:valid {
    background-image: none;
}

.dropdown-menu {
    min-width: 6rem !important;
    background-color: var(--control-background-color);
    color: #7ad25b;
}

.dropdown-menu>.dropdown-item {
    color: #7ad25b;
}

.hide {
    display: none;
}

.content-wrapper-scroll {
    overflow: scroll;
    height: 70%;
}

.textbox-control {
    background-color: #fff !important;
}

.question {
    font-size: large;
}

.authentication-client-name {
    font-size: large;
    font-weight: 600;
}


/************ For Tablets (S7,S6) ************/

@media only screen and (min-device-width: 712px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    .side-panel {
        position: unset;
        width: 330px;
    }
    .shepherd-footer .shepherd-button {
        font-size: 14px;
    }
    .steps-footer {
        position: fixed;
        bottom: 0%;
        background-color: white;
        padding: 7px !important;
    }
}


/* { End }*/